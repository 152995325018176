// extracted by mini-css-extract-plugin
export var copyrights = "index-module--copyrights--YS52L";
export var copyrightsWrapper = "index-module--copyrights-wrapper--Xmp3N";
export var footerSectionWrapper = "index-module--footer-section-wrapper--8fQNW";
export var infoBlock = "index-module--info-block--nNIY6";
export var infoContainer = "index-module--info-container--ZXL-y";
export var logo = "index-module--logo--p0LG5";
export var logoDescription = "index-module--logo-description--POf8X";
export var logoWrapper = "index-module--logo-wrapper--2xRQP";
export var mainContainer = "index-module--main-container--udmdJ";
export var navigationWrapper = "index-module--navigation-wrapper--k51oC";
export var socialsWrapper = "index-module--socials-wrapper--w3Jzy";
export var termsPrivacyPolicyButton = "index-module--terms-privacy-policy-button--vV39a";
export var termsPrivacyPolicyWrapper = "index-module--terms-privacy-policy-wrapper--XYG6N";