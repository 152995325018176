import React from 'react';
import * as styles from "../BackgroundImage/index.module.scss";

const Picture = ({backgroundImageDesktop, backgroundImageTablet, backgroundImageMobile, imageStyle="", alt}) => {
    return (
        <picture className={styles.backgroundImage + " " + imageStyle}>
            {backgroundImageMobile && <source  srcSet={backgroundImageMobile} media="(max-width: 767px)"/>}
            {backgroundImageTablet && <source  srcSet={backgroundImageTablet} media="(max-width: 1024px)"/>}
            {backgroundImageDesktop && <source  srcSet={backgroundImageDesktop} media="(min-width: 1025px)"/>}
            <img className={imageStyle}  src={backgroundImageDesktop} alt={alt || "alt"}/>
        </picture>
    );
};

export default Picture;