const ROUTES = {
  indexPage: "/",
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
  features: "/features",
  pricing: "/pricing",
  faq: "/faq",
  contactUs: "/contact-us",
  aboutUs: "/about-us",
  affiliateProgram: "/affiliate-program"
};

export default ROUTES;
