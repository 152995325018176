import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";

const SocialButtons = ({ imageUrl, title, alt, to }) => {
  return (
    <Link className={styles.socialButtonWrapper} to={to ?? "/"}>
      <img className={styles.image} src={imageUrl} alt={alt} />
      <span className={styles.title}>{title}</span>
    </Link>
  );
};

SocialButtons.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  to: PropTypes.string,
};

export default SocialButtons;
