import * as React from "react";
import PropTypes from "prop-types";

const SectionLayout = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

SectionLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default SectionLayout;
