import * as React from "react";
import PropTypes from "prop-types";

import * as styles from "./index.module.scss";
import HeroBanner from "../../images/terms-banner.inline.svg";
import Picture from "../Picture";

const BackgroundImage = ({Svg = HeroBanner, backgroundImageDesktop, backgroundImageTablet, backgroundImageMobile, children, imageStyle, alt="Background"}) => {
    return (
        <div className={styles.backgroundImageWrapper}>
            {backgroundImageDesktop ?
                <Picture
                    backgroundImageDesktop={backgroundImageDesktop}
                    backgroundImageTablet={backgroundImageTablet}
                    backgroundImageMobile={backgroundImageMobile}
                    imageStyle={imageStyle}
                    alt={alt}
                />
                : <Svg/>}
            {children}
        </div>
    );
};

BackgroundImage.propTypes = {
    Svg: PropTypes.any,
    imageUrl: PropTypes.string,
    children: PropTypes.element,
};

export default BackgroundImage;
