import React from 'react';
import * as styles from "../../index.module.scss";

const Hamburger = ({isActiveClass, onToggleMobileMenu}) => {
    return (
        <div className={isActiveClass ? styles.hamburger + " " + styles.hamburgerActive : styles.hamburger} onClick={onToggleMobileMenu}>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
        </div>
    );
};

export default Hamburger;