import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";

import ROUTES from "../../../../constants/routes";

const Navigation = () => {
  return (
    <div className={styles.navigationWrapper}>
      <Link className={styles.navigationButton} to={ROUTES.faq} alt="FAQ">
        FAQ
      </Link>
      <Link
        className={styles.navigationButton}
        to={ROUTES.features}
        alt="Features"
      >
        Features
      </Link>
      <Link
        className={styles.navigationButton}
        to={ROUTES.pricing}
        alt="Pricing"
      >
        Pricing
      </Link>
    </div>
  );
};

export default Navigation;
