import * as React from "react";
import { Link } from "gatsby";

import * as style from "./index.module.scss";

import logo from "../../../../../static/new-logo.svg";
import ROUTES from '../../../../constants/routes';

const Logo = () => {
  return (
    <Link className={style.logoWrapper} to={ROUTES.indexPage} title="Logo">
      <img src={logo} alt="Logo" />
    </Link>
  );
};

export default Logo;
