import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";

const BottomNavigationButton = ({ title, to }) => {
  return (
    <Link className={styles.bottomNavigationButton} to={to ?? "/"}>
      <span>{title}</span>
    </Link>
  );
};

BottomNavigationButton.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default BottomNavigationButton;
